.app {
  background-color: #FBECB2;
  color: #008170;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-height: 100vh; /* Change this line */
}

.logo-container {
  width: 200px;
  height: 200px;
  margin-top: 10px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.construction-text {
  margin-top: 20px;
  font-size: 1.5em; /* Make the text bigger */
}

.links {
  display: flex;
  flex-direction: column; /* Display the links in a column */
  align-items: center; /* Center the links */
  width: 100%;
  margin-top: 20px;
}

.link {
  border: 2px solid #005B41;
  border-radius: 40%;
  padding: 15px; /* Make the links bigger */
  margin-bottom: 10px; /* Add space between the links */
  text-decoration: none;
  color: #008170;
  font-size: 1.2em; /* Make the link text bigger */
}

footer {
  margin-top: 20px;
}

.footer-text {
  text-align: center;
}

/* ...existing CSS... */

.link {
  /* ...existing styles... */
  display: flex;
  align-items: center;
}

.link-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}